// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from "jquery";
import "popper.js";
import "bootstrap";
import toastr from "toastr";
import "select2";

toastr.options.toastClass = 'toastr';

function toggleFiscalResidenceFields() {
  if ($('#users_profile_natural_has_fiscal_residence_true').is(':checked')) {
    $('#fiscal-residence-fields').removeClass('d-none');
  } else {
    $('#fiscal-residence-fields').addClass('d-none');
  }
};

function chooseProfileForInvestment() {
  if ($('select#profile')[0] && $('a#select-share-link')[0]) {
    const profile = $('select#profile').val();

    $('a#select-share-link').attr('href', $('a#select-share-link').attr('href').replace(/&profile=\d*&?/, '') + `&profile=${profile}`);
  }
}

function confirmationTextCheck() {
  if ($('textarea#confirmation_text')[0] && $('#confirmation-text-warning')[0]) {
    if ($('textarea#confirmation_text').val().trim() === $('#confirmation-text-model').text().trim()) {
      $('#confirmation-text-warning').addClass('d-none');
      $('form input[type="submit"]').attr('disabled', null);
      $('textarea#confirmation_text').removeClass('is-invalid');
      $('textarea#confirmation_text').addClass('is-valid');
    } else if ($('textarea#confirmation_text').val()) {
      $('#confirmation-text-warning').removeClass('d-none');
      $('form input[type="submit"]').attr('disabled', 'disabled');
      $('textarea#confirmation_text').addClass('is-invalid');
      $('textarea#confirmation_text').removeClass('is-valid');
    }
  }
}

function updateConfirmationText() {
  const val = $('form input#subscription_amount').val();

  if (val) {
    $('span#confirmation-text-model-amount').html($('form input#subscription_amount').val());
    $('#confirmation-text-model').change();
  }
}

function birthDateWarning() {
  const date = +new Date($('#users_profile_natural_birth_date').val());
  const age = ~~((Date.now() - date) / (31557600000));

  if (age < 18 && date) {
    $('#birth-date-warning').removeClass('d-none');
    $('#users_profile_natural_birth_date').parent('.form-group').addClass('has-warning');
  } else {
    $('#birth-date-warning').addClass('d-none');
    $('#users_profile_natural_birth_date').parent('.form-group').removeClass('has-warning');
  }
}

function togglePolicallyExposedPersonField() {
  if ($('#users_profile_natural_is_politically_exposed_person_true').is(':checked')) {
    $('#is-politically-exposed-person-fields').removeClass('d-none');
  } else {
    $('#is-politically-exposed-person-fields').addClass('d-none');
  }
};

function toggleCloseToPolicallyExposedPersonField() {
  if ($('#users_profile_natural_is_close_to_politically_exposed_person_true').is(':checked')) {
    $('#is-close-politically-exposed-person-fields').removeClass('d-none');
  } else {
    $('#is-close-politically-exposed-person-fields').addClass('d-none');
  }
};

$(document).ready(() => {
  if ($('.flash.info')[0]) {
    toastr["info"]($('.flash.info').html());
  }
  if ($('.flash.success')[0]) {
    toastr["success"]($('.flash.success').html());
  }
  if ($('.flash.error')[0]) {
    toastr["error"]($('.flash.error').html());
  }
  if ($('.flash.alert')[0]) {
    toastr["error"]($('.flash.alert').html());
  }

  $('select').select2();

  toggleFiscalResidenceFields();
  $('body').on('change', 'input[type="radio"][name="users_profile_natural[has_fiscal_residence]"]', toggleFiscalResidenceFields);

  $('select#profile').on('change', chooseProfileForInvestment);
  chooseProfileForInvestment();

  updateConfirmationText();
  $('form input#subscription_amount').on('input', updateConfirmationText);

  $('textarea#confirmation_text').on('input', confirmationTextCheck);
  $('#confirmation-text-model').on('change', confirmationTextCheck);

  birthDateWarning();
  $('#users_profile_natural_birth_date').on('change', birthDateWarning);

  togglePolicallyExposedPersonField();
  $('body').on('change', 'input[type="radio"][name="users_profile_natural[is_politically_exposed_person]"]', togglePolicallyExposedPersonField);

  toggleCloseToPolicallyExposedPersonField();
  $('body').on(
    'change',
    'input[type="radio"][name="users_profile_natural[is_close_to_politically_exposed_person]"]', toggleCloseToPolicallyExposedPersonField
  );
});
